import React from 'react';
import { motion } from 'framer-motion';
import { Ten35Logo } from '../../utils/svgIcons';

function Loader() {
    const wrapperAnimate = {
        visible: {
            opacity: 1,
            transition: { duration: 1.5 }
        },
        hidden: {
            opacity: 0,
            transition: { duration: 1.5 }
        }
    };

    return (
        <motion.div
            variants={wrapperAnimate}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={'loader'}>
            <Ten35Logo color={'#fff'} />
        </motion.div>
    );
}

export default Loader;
