import React, { createContext, useContext, useState } from 'react';

const VideoPlayerContext = createContext({
    playingId: '',
    onPlayingId: () => {}
});

export function VideoPlayerProvider({ children }) {
    const [playingId, setPlayingId] = useState('');

    const onPlayingId = (playingId) => {
        setPlayingId(playingId);
    };

    const contextValue = {
        playingId,
        onPlayingId
    };

    return (
        <VideoPlayerContext.Provider value={contextValue}>{children}</VideoPlayerContext.Provider>
    );
}

export function useVideoPlayer() {
    return useContext(VideoPlayerContext);
}
