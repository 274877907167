import { useEffect } from 'react';
import ReactGA from 'react-ga';

const usePageTracking = () => {
    // history.listen(location => {
    //     ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
    //     ReactGA.set({ page: location.pathname})
    //     ReactGA.pageview(location.pathname);
    // })
    useEffect(() => {
        ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_CODE}`);
        // ReactGA.set({ page: window.location.pathname})
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
};
export default usePageTracking;
