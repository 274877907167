import React, { createContext, useContext, useState } from 'react';

const WorkItemContext = createContext({
    redirectFrom: '',
    homeAnchor: '',
    onWorkItemClick: () => {},
    onSetHomeAnchor: () => {}
});

export function WorkItemProvider({ children }) {
    const [redirectFrom, setRedirectFrom] = useState('');
    const [homeAnchor, setHomeAnchor] = useState('');

    const onWorkItemClick = (from) => {
        setRedirectFrom(from);
    };

    const onSetHomeAnchor = (anchor) => {
        setHomeAnchor(anchor);
    };

    const contextValue = {
        redirectFrom,
        homeAnchor,
        onWorkItemClick,
        onSetHomeAnchor
    };

    return <WorkItemContext.Provider value={contextValue}>{children}</WorkItemContext.Provider>;
}

export function useWorkAnimation() {
    return useContext(WorkItemContext);
}
