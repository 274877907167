import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import Loader from './components/loader/loader';

import { VideoPlayerProvider } from './hooks/ui/useVideoPlayer';
import usePageTracking from './hooks/api/useGAInit';
import { WorkItemProvider } from './hooks/ui/useWorkAnimation';

const HomeContainer = lazy(() => import('./containers/home'));
const WorkDetailContainer = lazy(() => import('./containers/work-detail'));
const PrivacyPolicyContainer = lazy(() => import('./containers/privacy'));
const NotFoundContainer = lazy(() => import('./containers/not-found'));
const NewsDetailContainer = lazy(() => import('./containers/news-detail'));

const AboutUsContainer = lazy(() => import('./containers/about-us'));
const OurWorkContainer = lazy(() => import('./containers/works'));
const TeamNewContainer = lazy(() => import('./containers/team-new'));
const NewNewsContainer = lazy(() => import('./containers/new-news'));
const CareersContainer = lazy(() => import('./containers/careers'));

function App() {
    usePageTracking();
    return (
        <Suspense fallback={<Loader />}>
            <VideoPlayerProvider>
                <WorkItemProvider>
                    <Router>
                        <AnimatePresence exitBeforeEnter={false}>
                            <div className={'app-container'}>
                                <Switch>
                                    <Route path="/privacy-policy">
                                        <PrivacyPolicyContainer />
                                    </Route>

                                    <Route path="/work/:slug">
                                        <WorkDetailContainer />
                                    </Route>

                                    <Route path="/about">
                                        <AboutUsContainer />
                                    </Route>

                                    <Route path="/work">
                                        <OurWorkContainer />
                                    </Route>

                                    <Route path="/team">
                                        <TeamNewContainer />
                                    </Route>

                                    <Route path="/news/:slug">
                                        <NewsDetailContainer />
                                    </Route>

                                    <Route path="/news">
                                        <NewNewsContainer />
                                    </Route>

                                    <Route path="/careers">
                                        <CareersContainer />
                                    </Route>

                                    <Route exact path="/">
                                        <HomeContainer />
                                    </Route>

                                    <Route path="*">
                                        <NotFoundContainer />
                                    </Route>
                                </Switch>
                            </div>
                        </AnimatePresence>
                    </Router>
                </WorkItemProvider>
            </VideoPlayerProvider>
        </Suspense>
    );
}

export default App;
